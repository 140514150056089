$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex",
			});
		});
	}
	try {
		if ($(window).width() > 1024.1) {
			fullPageInit();
		}
	} catch (error) {}

	setBackground();
	swiperInit();
	toggleFunc();
	tabFunc();
	aosInit();
	hoverTransition();
	filterItem();
	svgFunc();
	backTop();
});
window.onscroll = function () {
	fixHeader();
};

function backTop() {
	if ($("body").hasClass("homepage")) {
		if ($(window).width() > 1024.1) {
			$(".back-top").on("click", function (e) {
				fullpage_api.moveTo(1);
			});
		} else {
			$(".back-top").on("click", function (e) {
				e.preventDefault();
				$("html, body").animate(
					{
						scrollTop: 0,
					},
					"300"
				);
			});
		}
	}
	if (!$("body").hasClass("homepage")) {
		$(".back-top").on("click", function (e) {
			e.preventDefault();
			$("html, body").animate(
				{
					scrollTop: 0,
				},
				"300"
			);
		});
	}
}

function svgFunc() {
	$(".floor-list svg .path-g").on("click", function () {
		let id = $(this).attr("data-id");
		$(".path-g").removeClass("active");
		$(this).addClass("active");
		$(".home-floor .item-floor").removeClass("show");
		let offset = $("header").outerHeight();
		$("html, body").animate(
			{
				scrollTop: $("#" + id).offset().top - offset,
			},
			800,
			function () {
				// window.location.hash = hash;
			}
		);
	});
	$(".home-floor svg .path-g").on("mouseenter", function () {
		let id = $(this).attr("data-id");
		$(".path-g").removeClass("active");
		$(this).addClass("active");
		$(".home-floor .item-floor").removeClass("show");
		$("#" + id).addClass("show");
	});
}

function hoverTransition() {
	const hoverPoint = $(".home-service .item-overlay .hover-point");
	hoverPoint.on("mouseenter", function () {
		$(this).parent().is(".active") ||
			($(".home-service .item-overlay").removeClass("active"),
			$(this).parent().addClass("active"));
	});
	hoverPoint.on("mouseleave", function () {
		$(this).parent().addClass("fading").removeClass("active");
		setTimeout(() => $(this).parent().removeClass("fading"), 600);
	});
}
Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function toggleFunc() {
	document
		.querySelector(".menu-toggle")
		.addEventListener("click", function (event) {
			document.querySelector(".hamburger").classList.toggle("is-active");
			document.querySelector("header").classList.toggle("is-active");
			document
				.querySelector(".menu-toggle")
				.classList.toggle("is-toggle");
			document
				.querySelector(".site-menu-wrap")
				.classList.toggle("is-open");
			document.querySelector("body").classList.toggle("overlay-bg");
		});
	document
		.querySelector(".search-toggle")
		.addEventListener("click", function (event) {
			document
				.querySelector(".search-overlay")
				.classList.toggle("active");
			$(".search-toggle").toggleClass("icon-change");
		});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".search-toggle") &&
			!event.target.closest(".search-overlay")
		) {
			document
				.querySelector(".search-overlay")
				.classList.remove("active");
			document
				.querySelector(".search-toggle")
				.classList.remove("icon-change");
		}
	});
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}
}
function fixHeader() {
	if ($(window).width() > 1025) {
		if (!$("body").hasClass("homepage")) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				$("body").addClass("minimize");
			} else {
				$("body").removeClass("minimize");
			}
		}
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner .swiper-pagination",
			clickable: true,
			renderBullet: function (index, className) {
				return (
					'<span class="' +
					className +
					'">' +
					"0" +
					(index + 1) +
					"</span>"
				);
			},
		},
	});
	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".service-detail-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("service-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-service-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-service-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-service-" + index);

		var swiper = new Swiper(".service-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			centeredSlides: true,
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 30,
				},
				1024: {
					spaceBetween: 60,
					slidesPerView: 1,
				},
			},
			navigation: {
				nextEl: ".btn-next-service-" + index,
				prevEl: ".btn-prev-service-" + index,
			},
			pagination: {
				el: ".pagination-service-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	var topBanner = new Swiper(".partner-swiper  .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
			1280: {
				slidesPerView: 6,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var historyThumbs = new Swiper(".swiper-history-thumb  .swiper", {
		spaceBetween: 5,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		preventInteractionOnTransition: true,
		speed: 750,
		loop: false,
		thumbs: {
			swiper: historyThumbs,
		},
		navigation: {
			nextEl: ".swiper-history-thumb .next-nav",
			prevEl: ".swiper-history-thumb .prev-nav",
		},
	});

	var floorThumb = new Swiper(".swiper-floor-thumb .swiper", {
		spaceBetween: 0,
		breakpointsInverse: true,
		preventInteractionOnTransition: true,
		slideToClickedSlide: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 6,
			},
			1280: {
				slidesPerView: "auto",
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var floorMain = new Swiper(".swiper-floor-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: floorThumb,
		},
		navigation: {
			nextEl: ".swiper-floor-main .next-nav",
			prevEl: ".swiper-floor-main .prev-nav",
		},
	});

	var floorSwiper = new Swiper(".floor-swiper-wrap .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var floorSwiper = new Swiper(".home-service-swiper .swiper", {
		// Optional parameters
		speed: 600,
		lazy: {
			loadPrevNext: true,
		},
		preventInteractionOnTransition: true,
		observer: true,
		spaceBetween: 0,
		observeParents: true,
		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	$(".trinity-swiper .swiper").each(function (index, element) {
		const $element = $(element);
		const parent = $element.parent();
		const prev = parent.find(".nav-prev");
		const next = parent.find(".nav-next");
		const options = {
			speed: 750,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
		};
		$element.addClass(`t-${index}`);
		prev.addClass(`bp-${index}`);
		next.addClass(`bn-${index}`);
		new Swiper(`.t-${index}`, {
			...options,
			navigation: {
				...options.navigation,
				nextEl: `.bn-${index}`,
				prevEl: `.bp-${index}`,
			},
		});
	});

	var swiperGrid = new Swiper(".grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		slidesPerView: 4,
		grid: {
			rows: 2,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
}

function filterItem() {
	$(".filter-display-wrapper").each(function () {
		let x = 5;
		$(this).find(".col").slice(0, 5).show();
		let size = $(this).find(".col").length;
		if (size < x || size == x) {
			$(this).find(".ajax-center-wrap").addClass("disble-btn");
		}
		$(".filter-display-wrapper")
			.find(".btn-ajax-link")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 3;
				$(this)
					.parents(".filter-display-wrapper")
					.find(".col")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
}

//Tab Func
function tabFunc() {
	const tabs = document.querySelectorAll(".tabs");
	tabs.forEach((tab) => {
		const tabMenuItems = tab.querySelectorAll(".tab-menu-item");

		tabMenuItems.forEach((item) => {
			item.addEventListener("click", (e) => {
				// Remove active class from all tab menu items and tab pane items within the same tab
				tab.querySelectorAll(".tab-menu-item").forEach((item) =>
					item.classList.remove("active")
				);
				tab.querySelectorAll(".tab-panel").forEach((item) =>
					item.classList.remove("active")
				);

				// Add active class to clicked tab menu item and corresponding tab pane item
				e.target.classList.add("active");
				tab.querySelector(`#${e.target.dataset.tab}`).classList.add(
					"active"
				);
			});
		});
	});
}
// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function aosInit() {
	AOS.init({
		// Global settings:

		disable: window.innerWidth < 1024,
		startEvent: "load", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 20, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 0, // the delay on throttle used while scrolling the page (advanced)
		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 120, // values from 0 to 3000, with step 50ms
		duration: 500, // values from 0 to 3000, with step 50ms
		easing: "ease-in-out-back", // default easing for AOS animations
		once: true,
		mirror: true, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
}
function fullPageInit() {
	try {
		var myFullpage = new fullpage("#fullpage", {
			//Navigation
			bigSectionsDestination: null,
			menu: "#header,#menu-parallax,#fixed-side-nav",
			lockAnchors: false,
			fixedElements:
				"#header,#menu-parallax,#fixed-side-nav",
			anchors: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"lastPage",
			],
			navigationTooltips: [
				"firstPage",
				"secondPage",
				"thirdPage",
				"fourthPage",
				"fifthPage",
				"sixthPage",
				"seventhPage",
				"lastPage",
			],
			slidesNavigation: true,
			slidesNavPosition: "bottom",
			showActiveTooltip: true,
			//Scrolling
			css3: true,
			offsetSections: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: false,
			fitToSectionDelay: 600,
			scrollBar: false,
			easing: "easeInOutCubic",
			easingcss3: "ease",
			loopBottom: false,
			loopTop: false,
			loopHorizontal: true,
			continuousVertical: false,
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,
			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,
			//Design
			controlArrows: true,
			verticalCentered: true,
			resize: false,
			responsiveWidth: 0,
			responsiveHeight: 0,
			//Custom selectors
			sectionSelector: "section",
			dragAndMove: true,
			afterLoad: function () {
				$(".fp-table.active .aos-init").addClass("aos-animate");
			},
		});
	} catch (error) {}
}
$(document).ready(function () {
	jQuery(function ($) {
		"use strict";
		var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"
		var $counters = $(".counter");
		/* Start counting, do this on DOM ready or with Waypoints. */
		$counters.each(function (ignore, counter) {
			var waypoint = new Waypoint({
				element: $(this),
				handler: function () {
					counterUp(counter, {
						duration: 2000,
						delay: 200,
					});
					this.destroy();
				},
				offset: "top-in-view",
			});
		});
	});
});

